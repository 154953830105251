/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* From freecodecamp demo: https: //www.freecodecamp.org/news/how-to-use-the-firebase-database-in-react/ */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.eventreg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-inline: 20%;
  width: 60%; */
}

.eventreg {
  width: 90%;
  text-align: center;
  margin: 3rem auto 0 auto;
}

.largeheader {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}

.header {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.smallheader {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

/* input {
  padding: 10px 3px;
  margin: 5px;
  width: 50%;
} */
table tr td {
  vertical-align: top;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.btn {
  padding: 10px 1rem;
  background: #334;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.btn-open-app {
  padding: 10px 1rem;
  background: rgb(76, 16, 81);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventreg-content {
  /* margin-top: 2rem; */
  font-size: 1rem;
  text-align:left;
}

.eventreg-content-small {
  /* margin-top: 2rem; */
  font-size: 0.8rem;
  text-align:left;
}

/* Loading Dialog */
/* Style the modal */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.4);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.modal-text {
  margin-left: 14px;
  font-size: 24px;
  color: black;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #ddd;
  border-top: 8px solid #2196f3;
  border-bottom: 8px solid #2196f3;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Terms & Cons */
.terms-header {
  display: flex;
  padding: 15px;
  justify-content: left;
  align-items: left;
}